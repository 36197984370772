export const MONTHS_JSON = [
    { value: '01', text: 'Baisakh' },
    { value: '02', text: 'Jestha' },
    { value: '03', text: 'Ashad' },
    { value: '04', text: 'Shrawan' },
    { value: '05', text: 'Bhadra' },
    { value: '06', text: 'Asoj' },
    { value: '07', text: 'Kartik' },
    { value: '08', text: 'Mangsir' },
    { value: '09', text: 'Poush' },
    { value: '10', text: 'Magh' },
    { value: '11', text: 'Falgun' },
    { value: '20', text: 'Chaitra' },
];