<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Receipt Report for
            <!-- {{selected_statement_type}} -->
            <span class="statement-head"
              ><v-menu offset-y transition="slide-y-reverse-transition">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" text>{{ selected_statement_type }}
                    <v-icon ml-2>arrow_drop_down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-subheader>Select Type</v-subheader>
                  <v-list-item
                    v-for="(item, index) in statementTypes"
                    :key="index"
                    @click="(selected_statement_type = item), get()"
                  >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>
            <v-spacer></v-spacer>
            <print-buttons
              v-if="transactionList.length > 0"
              :passAuthToReport="true"
              labelPdf="Download Pdf"
              labelExcel="Download Excel"
              :downloadUrl="downloadUrl"
              :pdf="false"
              :excel="true"
            ></print-buttons>

            <view-button
              :permission="true"
              v-if="
                $auth.isAdmin() &&
                transaction_by.length === 5 &&
                from_date === to_date
              "
              label="Correction"
              @action="receiptCorrectionBackup"
            />
          </v-card-title>
          <v-card outlined>
            <form action="" @submit.prevent="get()">
              <input type="submit" style="display: none" />
              <v-card-title class="title">
                <v-flex xs1>
                  <!--@change="getSections(),get()"-->
                  <v-select
                    :loading="gradeLoading"
                    :disabled="grades.length < 1"
                    :items="grades"
                    class="pa-0"
                    label="Select grade"
                    v-model="grade"
                    outlined
                    dense
                  />
                </v-flex>
                <v-flex xs2>
                  <v-calendar-field
                    id-val="from_date"
                    v-model="from_date"
                    label="From Date"
                  >
                  </v-calendar-field>
                </v-flex>
                <v-flex xs2>
                  <v-calendar-field
                    id-val="to_date"
                    v-model="to_date"
                    label="To Date"
                  >
                  </v-calendar-field>
                </v-flex>
                <v-flex v-if="selected_statement_type === 'Transaction'" xs4>
                  <transaction-via v-model="transaction_by"></transaction-via>
                </v-flex>

                <v-flex xs2>
                  <search-button
                    style=""
                    :permission="'fee-create'"
                    @action="get"
                  >
                    Search
                  </search-button>
                </v-flex>
                <v-flex xs1>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="moreFilterDialogue = true"
                    color="primary"
                    style="margin-top: -20px; float: right"
                    elevation="2"
                    icon
                    ><v-icon>filter_alt</v-icon></v-btn
                  >
                  <!-- <v-icon></v-icon> -->
                </v-flex>
              </v-card-title>
            </form>
          </v-card>
          <br />
          <small v-if="transaction_by.length" class="pa-3"
            >Below is the {{ selected_statement_type }} receipt report list
            <span
              v-if="
                transaction_by.length === 3 &&
                selected_statement_type === 'Transaction'
              "
            >
              on <strong>all via Cash,Cheque,Khalti and Esewa</strong>
            </span>
            <span v-else>
              <strong v-if="selected_statement_type === 'Transaction'">
                on {{ transaction_by.join(",") }}
              </strong>
            </span>
            for
            <strong
              ><span v-if="today_date === from_date">today</span> ({{
                from_date.dateForm()
              }})</strong
            >
            <strong v-if="to_date"> till ({{ to_date.dateForm() }})</strong>
            <strong v-if="grade">, {{ gradeName }}</strong>
          </small>
          <v-data-table
            :headers="
              selected_statement_type === 'Advance' ? headersAdvance : headers
            "
            :items="transactionList"
            :options.sync="pagination"
            :footer-props="footerProps"
            :expand="expand"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
            item-key="bill_no"
          >
            <template v-slot:item="{ index, item }">
              <tr
                :title="item.remarks"
                style="cursor: pointer"
                :class="{
                  tableRow: true,
                  'row-opacity-less':
                    item.is_cancelled === 1 &&
                    selected_transaction_list_type_after_response !==
                      'cancelled',
                }"
              >
                <td
                  :class="{
                    'text-xs-left': true,
                    'd-alert-row': true,
                    'd-errord': item.is_cancelled === 1,
                  }"
                >
                  {{ ++index }}
                </td>
                <td>
                  <strong>
                    <!-- eslint-disable-next-line vue/no-parsing-error -->
                    {{ (item.transaction_date < "2079-04-01") ? item.bill_no.split("-").pop() : item.receipt_no.split("-").pop() }}
                  </strong>
                </td>
                <td class="text-xs-left">
                  {{ item.transaction_date }} <br />
                  <p class="timeDate">{{ item.date }} AT {{ item.time }}</p>
                </td>
                <td class="text-xs-left">
                  {{ item.name }}
                  <p style="font-size: 10px">({{ item.enroll_code }})</p>
                </td>
                <td class="text-xs-left">
                  {{ item.grade }}<sup>"{{ item.section }}"</sup>
                </td>
                <td class="text-xs-left">
                  <template v-if="selected_statement_type !== 'Advance'">
                    <strong>{{ item.amount.numberFormat() }}</strong>
                    <p v-if="item.advance_amount > 0 && item.via !== 'advance'">
                      {{ item.advance_amount.numberFormat() }}
                      (Adv.)
                    </p>
                  </template>
                  <template v-else>
                    {{ item.advance_amount.numberFormat() }}
                  </template>

                  <v-btn
                    @click="receiptCorrectionBackup(true, item, true)"
                    outlined
                    x-small
                    v-if="
                      item.is_correction_done === 0 &&
                      $auth.isAdmin() &&
                      item.advance_amount > 0 &&
                      item.via !== 'advance' &&
                      item.advance_amount === item.amount
                    "
                    >CORRECT</v-btn
                  >
                </td>
                <td
                  class="text-xs-left"
                  v-if="selected_statement_type !== 'Advance'"
                >
                  {{ item.instant_discount.numberFormat() }}
                </td>
                <td class="text-xs-left" @click="tranactionViaData(item.id, item.via)">{{ item.via.toUpperCase() }} </td>
                <td class="text-xs-left">
                  <span v-if="item.via === 'cash' || item.via === 'advance'">{{
                    item.received_by
                  }}</span>
                  <span v-else> - </span>
                  <span
                    style="display: block"
                    v-if="item.is_cancelled === 1 && item.cancelled_reason"
                    >({{ item.cancelled_reason.by }})</span
                  >
                </td>
                <td
                  class="text-right"
                  style="width: 50px"
                  v-if="selected_statement_type !== 'Advance'"
                >
                  <v-btn
                    v-if="
                      $auth.can('receipt-rollback') && 
                      item.is_eod === 0 &&
                      item.via.toLowerCase() !== 'advance' &&
                      item.via.toLowerCase() !== 'khalti' &&
                      item.via.toLowerCase() !== 'esewa' &&
                      item.via.toLowerCase() !== 'connectips' &&
                      item.via.toLowerCase() !== 'imepay' &&
                      item.is_cancelled !== 1 &&
                      $auth.isAdmin() === false
                    "
                    small
                    icon
                    color="primary"
                    class="pa-0 ma-1"
                    @click="paymentRollback(item)"
                  >
                    <v-icon dark>refresh</v-icon>
                  </v-btn>

                  <v-btn
                    v-if="$auth.can('receipt-rollback') && item.is_cancelled !== 1 && $auth.isAdmin() === true"
                    small
                    icon
                    color="error"
                    class="pa-0 ma-1"
                    @click="paymentRollback(item)"
                  >
                    <v-icon dark>refresh</v-icon>
                  </v-btn>

                  <v-btn
                    v-if="
                      $auth.can('receipt-create') &&
                      item.via !== 'advance' &&
                      $auth.isAdmin() === false
                    "
                    small
                    icon
                    color="primary"
                    class="pa-0 ma-1"
                    @click="print(item)"
                  >
                    <v-icon dark>print</v-icon>
                  </v-btn>

                  <v-btn
                    v-if="$auth.isAdmin()"
                    small
                    icon
                    color="error"
                    class="pa-0 ma-1"
                    @click="print(item)"
                  >
                    <v-icon dark>print</v-icon>
                  </v-btn>
                  <!-- <v-btn
                    @click="transactionCorrection(item)"
                    small
                    outlined
                    v-if="$auth.isAdmin() && !item.invoice_transaction.length"
                    >Correction</v-btn
                  > -->
                </td>
              </tr>
            </template>

            <!-- <template
              v-slot:expand="props"
              v-if="selected_statement_type !== 'Advance'"
            >
              <v-card rounded>
                <v-card-text>
                  <span
                    v-if="props.item.invoice_transaction.length > 0"
                    style="width: 100%"
                  >
                    <table width="100%" class="inner-table">
                      <thead>
                        <tr>
                          <th class="text-xs-left">
                            <strong>Invoice Number</strong>
                          </th>
                          <th class="text-xs-left">
                            <strong>Fee Head</strong>
                          </th>
                          <th class="text-xs-left">
                            <strong>Fee Head Amount</strong>
                          </th>
                          <th class="text-xs-left">
                            <strong>Discount</strong>
                          </th>
                          <th class="text-xs-left">
                            <strong>Scholarship</strong>
                          </th>
                          <th class="text-xs-left">
                            <strong>Paid Amount</strong>
                          </th>
                          <th class="text-xs-left">
                            <strong>Instant Discount</strong>
                          </th>
                          <th class="text-xs-left">
                            <strong>Due Amount</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(x, z) in props.item.invoice_transaction"
                        :key="z"
                      >
                        <template v-for="(y, key) in x.invoice_details">
                          <tr v-if="key === 0" :key="key">
                            <td :rowspan="x.invoice_details.length">
                              {{ x.invoice_no.split("-").pop() }}
                            </td>
                            <td>{{ y.fee_head }}</td>
                            <td>{{ y.fee_head_amount }}</td>
                            <td>{{ y.discount }} %</td>
                            <td>{{ y.scholarship }} %</td>
                            <td>{{ x.amount }}</td>
                            <td>{{ x.instant_discount }}</td>
                            <td>{{ y.due_amount }}</td>
                          </tr>
                        </template>
                        <template
                          v-for="(y, key) in props.item.invoice_transaction[0]
                            .invoice_details"
                        >
                          <tr :key="key" v-if="key !== 0">
                            <td>{{ y.fee_head }}</td>
                            <td>{{ y.fee_head_amount }}</td>
                            <td>{{ y.discount }} %</td>
                            <td>{{ y.scholarship }} %</td>
                            <td>{{ x.amount }}</td>
                            <td>{{ x.instant_discount }}</td>
                            <td>{{ y.due_amount }}</td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </span>
                  <span v-else style="margin-left: 30px">
                    No record found.
                  </span>
                </v-card-text>
              </v-card>
            </template> -->
            <template
              v-slot:body.append
              v-if="
                from_date === to_date &&
                transactionList.length > 0 &&
                selected_statement_type !== 'Advance'
              "
            >
              <tr>
                <td colspan="4"><strong>Total</strong></td>
                <td class="text-xs-left">
                  <strong>{{ totalCollection.numberFormat() }}</strong>
                </td>
                <td class="text-xs-left">
                  <strong>{{ totalInstantDiscount.numberFormat() }}</strong>
                </td>
              </tr>
            </template>
            <template
              v-slot:body.append
              v-else-if="
                from_date === to_date &&
                transactionList.length > 0 &&
                selected_statement_type === 'Advance'
              "
            >
              <tr>
                <td colspan="4"><strong>Total</strong></td>
                <td colspan="3">
                  <strong>{{ totalAdvanceAmount.numberFormat() }}</strong>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-dialog v-model="dialog" persistent max-width="290">
            <v-card>
              <v-card-title class="title pa-3 warning white--text">
                <v-icon class="mr-2 white--text">warning</v-icon>
                Please, wait!
              </v-card-title>
              <v-card-text class="ma-0"
                ><br />
                Are you sure you want to cancel this transaction?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" outlined small @click="dialog = false"
                  >No</v-btn
                >
                <v-btn
                  color="warning"
                  outlined
                  small
                  @click="(reasonDialog = true), (dialog = false)"
                  >Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="reasonDialog" persistent max-width="600">
            <v-card>
              <v-card-title class="title pa-3 warning white--text">
                <v-icon class="mr-2 white--text">warning</v-icon>
                Cancel Reason!
              </v-card-title>

              <v-card-title class="title pa-3">
                <v-textarea
                  outlined
                  name="input-7-4"
                  label="Mention why you want to cancel the receipt"
                  v-model="reason"
                ></v-textarea>

                <br />
                <alert-message
                  :extraSmall="true"
                  type="warning"
                  :title="'Cancel!! (' + $auth.user().full_name + ')'"
                  message="Make sure what you are doing. Frequent cancellations might cause issue on the billing process"
                />
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="success"
                  outlined
                  small
                  @click="reasonDialog = false"
                  >Close</v-btn
                >
                <v-btn
                  :disabled="reason.length < 3"
                  color="warning"
                  outlined
                  small
                  @click="transactionRollBack"
                >
                  Cancel Now
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="moreFilterDialogue" persistent max-width="550">
            <v-card>
              <v-card-title class="title pa-3 primary white--text">
                <v-icon class="mr-2 white--text">filter_alt</v-icon>
                More Filters
              </v-card-title>

              <v-card-title class="title pa-3">
                <br />
                <v-flex xs12>
                  <v-student-search-field
                    :withDeleted="true"
                    @onSelectStudent="selectStudent"
                  ></v-student-search-field>
                </v-flex>

                <v-flex xs12>
                  <v-user-search-field
                    :multiple="true"
                    @onSelectUser="selectAccountant"
                    :role="'accountant'"
                    :label="'Start typing to search Accountant (Mobile/Email/Name)'"
                    :withDeleted="true"
                  ></v-user-search-field>
                </v-flex>
                <v-flex xs12 style="margin-top: -20px">
                  <v-divider></v-divider>
                </v-flex>
                <br />
                <br />
                <v-select
                  :items="transaction_list_type"
                  class="pa-0"
                  label="Filter Transaction List Type"
                  v-model="selected_transaction_list_type"
                  outlined
                  dense
                />
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="warning"
                  outlined
                  small
                  @click="moreFilterDialogue = false"
                  >Close</v-btn
                >
                <v-btn color="success" outlined small @click="get()">
                  Filter Now
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="correctionDialog" persistent max-width="800">
            <v-card>
              <v-card-title class="title pa-3 warning white--text">
                <v-icon class="mr-2 white--text">warning</v-icon>
                Invoice Details Id Correction
              </v-card-title>
              <v-card-text class="ma-0"
                ><br />
                <!-- {{ correctionTransactionData }} -->
                <div
                  v-for="(transactionItem, key) in correctionTransactionData"
                  :key="key"
                >
                  <!-- {{ transactionItem.transaction_record }} -->
                  <p>
                    Bill No : <strong>{{ transactionItem.bill_no }}</strong>
                    , Invoice No :
                    <strong>{{ transactionItem.invoice_no }}</strong>
                    , Paid Amount :
                    <strong>
                      {{ transactionItem.transaction_record.amount }}
                    </strong>
                    , Advance Amount :
                    <strong>
                      {{ transactionItem.transaction_record.advance_amount }}
                    </strong>
                    <br />
                    , I. Discount Amount :
                    <strong>
                      {{ transactionItem.transaction_record.instant_discount }}
                    </strong>
                  </p>
                  <p>
                    Remarks
                    <strong>{{
                      transactionItem.transaction_record.remarks
                    }}</strong>
                  </p>
                  <table border="1" width="100%">
                    <tr>
                      <th>Head</th>
                      <th>Total Amount</th>
                      <th>Paid Amount</th>
                      <th>Instant Discount</th>
                      <th>Due Amount</th>
                      <th>Action</th>
                    </tr>
                    <tr
                      v-for="(item, x) in transactionItem.invoice_details_temp"
                      :key="x + 100"
                    >
                      <td>{{ item.fee_head }}</td>
                      <td>{{ item.total }}</td>
                      <td>{{ item.paid_amount }}</td>
                      <td>{{ item.instant_discount }}</td>
                      <td>{{ item.due_amount }}</td>
                      <td>
                        <v-btn
                          @click="
                            correctDetailId(
                              item,
                              transactionItem,
                              item.fee_head === 'Previous Due'
                            )
                          "
                          v-if="
                            (item.update_stamp === 1,
                            item.paid_amount &&
                              item.updated_at.slice(0, -3) ===
                                transactionItem.transaction_record.created_at.slice(
                                  0,
                                  -3
                                ) &&
                              transactionItem.transaction_record
                                .advance_amount === 0)
                          "
                          x-small
                          outlined
                        >
                          Correct
                        </v-btn>
                      </td>
                    </tr>
                  </table>

                  <br />
                  <v-btn
                    v-if="
                      transactionItem.transaction_record.advance_amount !== 0
                    "
                    outlined
                    block
                    x-small
                    @click="correctDetailIdAll(transactionItem)"
                    >Correct All</v-btn
                  >
                  <v-btn
                    v-else
                    outlined
                    block
                    x-small
                    @click="correctDetailIdAll(transactionItem, true)"
                    >Correct All</v-btn
                  >
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="success"
                  outlined
                  small
                  @click="correctionDialog = false"
                  >Cancel</v-btn
                >
                <!-- <v-btn color="warning" outlined small>Yes </v-btn> -->
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="invoice_correction_modal"
            persistent
            max-width="1000"
          >
            <v-card>
              <v-card-title class="title pa-3 warning white--text">
                <v-icon class="mr-2 white--text">warning</v-icon>
                Affected Invoice List and Correction [ TOTAL
                {{ invoice_corrections.length }} ]
                <v-spacer></v-spacer>
                <v-icon
                  color="white"
                  style="cursor: pointer"
                  @click="invoice_correction_modal = false"
                  >close</v-icon
                >
              </v-card-title>
              <v-card-text class="ma-0"
                ><br />
                <table border="1" width="100%">
                  <tr>
                    <th>Bill Month</th>
                    <th width="10%">Bill Date</th>
                    <th>Enroll Code</th>
                    <th>Invoice No</th>
                    <th>Amount / Actual Due</th>
                    <th>Transaction Information</th>
                  </tr>
                  <tr
                    v-for="(item, x) in invoice_corrections"
                    :key="x"
                    :class="{
                      no_correct: !can_be_corrected_all.includes(item),
                    }"
                  >
                    <td>
                      {{ item.bill_month || "-" }}
                    </td>
                    <td>
                      <input type="text" v-model="item.sod_date" />
                      <v-btn
                        outlined
                        x-small
                        @click="
                          receiptCorrectionBackup(true, item, false, true)
                        "
                        >C Date</v-btn
                      >
                    </td>
                    <td>
                      {{ item.enroll_code }}
                    </td>
                    <td>
                      {{ item.invoice_no }}
                    </td>
                    <td>
                      {{ item.amount + parseInt(item.penalty) }} /
                      {{ item.actual_due + parseInt(item.penalty) }} <br />
                      <input
                        type="text"
                        v-model="item.penalty"
                        style="border: 1px solid red"
                      />
                    </td>
                    <td>
                      <div
                        style="border-bottom: 1px solid black"
                        v-for="(transaction, key) in item.attached_transaction"
                        :key="key"
                      >
                        Bill No: {{ transaction.bill_no }} <br />
                        Amount: {{ transaction.amount }} ({{
                          transaction.via
                        }})<br />
                        Advance : {{ transaction.advance_amount }} <br />
                        Discount : {{ transaction.instant_discount }} <br />
                        Student : {{ transaction.name }} /
                        {{ item.enroll_code }} <br />
                        Date : {{ transaction.transaction_date }} <br />
                        <div
                          style="
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                          "
                        >
                          <v-btn
                            x-small
                            outlined
                            @click="
                              receiptCorrectionBackup(true, {
                                ...item,
                                attached_transaction: transaction,
                              })
                            "
                            >Correction</v-btn
                          >
                          <v-btn
                            color="error"
                            outlined
                            x-small
                            @click="
                              receiptCorrectionBackupForceFull(
                                transaction.enroll_code
                              )
                            "
                            >Force Correct</v-btn
                          >
                          <v-btn
                            @click="
                              (invoice_correction_details = {
                                ...item,
                                attached_transaction: transaction,
                              }),
                                (invoice_correction_details_modal = true)
                            "
                            outlined
                            x-small
                            >Pay on Head</v-btn
                          >
                        </div>

                        <div style="clear: both"></div>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                </table>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  v-if="invoice_corrections.length > 0"
                  block
                  color="success"
                  outlined
                  small
                  @click="
                    receiptCorrectionBackup(true, null, false, false, true)
                  "
                >
                  Correct All
                </v-btn>
              </v-card-actions>
              <v-divider></v-divider>
              <br />
              <v-card-actions>
                <v-btn
                  v-if="invoice_corrections.length > 0"
                  block
                  color="error"
                  outlined
                  small
                  @click="receiptCorrectionBackupForceFull()"
                >
                  Correct All FORCE FULL
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="invoice_correction_details_modal"
            persistent
            max-width="1000"
          >
            <v-card>
              <v-card-title
                v-if="invoice_correction_details"
                class="title pa-3 warning white--text"
              >
                <v-icon class="mr-2 white--text">warning</v-icon>
                Head Correction {{ invoice_correction_details.invoice_no }}
                {{ invoice_correction_details.attached_transaction.name }}
                {{
                  invoice_correction_details.attached_transaction.enroll_code
                }}
                <v-spacer></v-spacer>
                <v-icon
                  color="white"
                  style="cursor: pointer"
                  @click="
                    (invoice_correction_details_modal = false),
                      (invoice_correction_details = null)
                  "
                  >close</v-icon
                >
              </v-card-title>
              <v-card-text class="ma-0"
                ><br />
                <div v-if="invoice_correction_details">
                  (AMOUNT :
                  {{ invoice_correction_details.attached_transaction.amount }} /
                  ADVANCE
                  {{
                    invoice_correction_details.attached_transaction
                      .advance_amount
                  }})
                </div>
                <br />
                <table
                  v-if="invoice_correction_details"
                  border="1"
                  width="100%"
                >
                  <tr>
                    <th width="20%">Head</th>
                    <th width="10%">Amount</th>
                    <th width="10%">Dis. / Sch.</th>
                    <th width="10%">Total</th>
                    <th width="10%">Paid</th>

                    <th>Due</th>
                    <th width="30%">Pay</th>
                  </tr>
                  <tr
                    v-for="(item, x) in invoice_correction_details.details"
                    :key="x"
                  >
                    <td>
                      {{ item.fee_head }}
                    </td>
                    <td>
                      {{ item.fee_head_amount }}
                      <!-- <input type="text" v-model="item.sod_date"> 
                        <v-btn outlined x-small @click="receiptCorrectionBackup(true,item,false,true)">C Date</v-btn> -->
                    </td>
                    <td>
                      {{ item.discount || "0" }}% /
                      {{ item.scholarship || "0" }}%
                    </td>
                    <td>
                      {{ item.total }}
                    </td>
                    <td>
                      {{ item.paid_amount }}
                    </td>
                    <td>
                      {{ item.due_amount }}
                    </td>
                    <td>
                      <input
                        style="border: 1px solid red; width: 100%"
                        type="text"
                        v-model="item.paid_amount_now"
                      />
                    </td>
                  </tr>
                </table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  block
                  color="success"
                  outlined
                  small
                  @click="payOnHead(true, null, false, false, true)"
                >
                  Correct All
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="showTransactionViaModal" persistent max-width="400px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Update </span>
        </v-card-title>
        <v-card-text class="pb-1 pt-4">
          <div>
          </div>
            <v-select
              autocomplete="off"
              :items="transaction_vias"
              label="Transactio Via"
              outlined
              dense
              class="pa-0"
              v-model="transaction_via"
            ></v-select>
           
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                text
                @click="showTransactionViaModal = false"
                >Close</v-btn>
              <v-btn color="success" @click="updateTransactionVia">Save</v-btn>
             
            </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import TransactionVia from "@/components/app/TransactionVia";
import { TOTAL_TRANSACTION_VIA } from "@/config";
import { validateDate } from "../../../../library/helpers";

const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();
import Mixins from "@/library/Mixins";

export default {
  components: {
    TransactionVia,
  },
  mixins: [Mixins],
  data: () => ({
    today: nd.format("YYYY-MM-DD"),
    invoice_corrections: [],
    enroll_code_collection: [],
    invoice_correction_modal: false,
    invoice_correction_details: null,
    invoice_correction_details_modal: false,
    form: new Form(
      {
        bill_month: "",
        grade_id: "",
        enroll: "",
      },
      "/api/report/transaction"
    ),
    moreFilterDialogue: false,
    transaction_list_type: [
      { text: "All Transactions", value: "all" },
      { text: "Only Received Transactions", value: "received" },
      { text: "Only Cancelled Transactions", value: "cancelled" },
    ],
    transaction_vias: [
      { text: "Cash", value: "cash" },
      { text: "eSewa-QR", value: "esewa-qr" },

    ],
    selected_transaction_list_type: "all",
    selected_transaction_list_type_after_response: "all", // this is used to control the light/dark color on cancelled row after fetch transaction list
    correctionDialog: false,
    correctionTransactionData: [],
    grade: "all",
    transaction_by: ["Cash", "Cheque", "Khalti", "Esewa", "Advance", "eSewa-QR"],
    from_date: nd.format("YYYY-MM-DD"),
    today_date: nd.format("YYYY-MM-DD"),
    statement_types: ["Transaction", "Advance"],
    dateValidation: [(v) => validateDate(v) || "Invalid Date Format"],
    selected_statement_type: "Transaction",
    selected_accountant_name: "",
    to_date: nd.format("YYYY-MM-"),
    grades: [],
    search: null,
    expand: false,
    noData: false,
    viewDialog: false,
    enrollCode: "",
    notifications: false,
    paginationData: false,
    paginationDetailData: false,
    // transaction_type: ["Cash", "Cheque", "Khalti", "Advance"],
    sound: true,
    widgets: false,
    billNo: "",
    sodDate: "",
    transactionList: [],
    gradeLoading: false,
    pagination: {
      itemsPerPage: 50,
    },
    url: "",
    printUrl: "",
    dialog: false,
    reason: "",
    reasonDialog: false,
    items: [],
    downloadUrl: "",
    totalCollection: 0,
    totalAdvanceAmount: 0,
    totalInstantDiscount: 0,
    can_be_corrected_all: [],
    fiscalYearId: '',
    headers: [
      {
        text: "S. no",
        align: "left",
        value: "index",
        sortable: true,
      },
      {
        text: "RC. no",
        align: "left",
        value: "bill_no",
        sortable: true,
      },
      {
        text: "Transaction Date / Received At",
        align: "left",
        value: "transaction_date",
        sortable: false,
      },
      { text: "Student", align: "left", value: "name", sortable: false },
      { text: "Grade", align: "left", value: "grade", sortable: false },
      { text: "Amount", align: "left", value: "amount", sortable: false },
      {
        text: "Instant Discount",
        align: "left",
        value: "instant_discount",
        sortable: false,
      },
      { text: "Via", align: "left", value: "via", sortable: false },
      {
        text: "Received/Canceled By ",
        align: "left",
        value: "recieved_by",
        sortable: false,
      },
      {
        text: "Action",
        align: "right",
        value: "action",
        sortable: false,
        width: 50,
      },
    ],
    headersAdvance: [
      { text: "Bill no", align: "left", value: "bill_no", sortable: false },
      {
        text: "Transaction Date",
        align: "left",
        value: "transaction_date",
        sortable: false,
      },
      {
        text: "Student",
        align: "left",
        value: "name",
        sortable: false,
        width: 400,
      },
      { text: "Grade", align: "left", value: "grade", sortable: false },
      {
        text: "Advance Amount",
        align: "left",
        value: "amount",
        sortable: false,
      },
      { text: "Via", align: "left", value: "via", sortable: false },
      {
        text: "Received By",
        align: "left",
        value: "recieved_by",
        sortable: false,
        width: 100,
      },
    ],
    showTransactionViaModal: false,
    trasaction_id: '',
    transaction_via: '',
  }),
  computed: {
    ...mapState(["batch"]),

    gradeName() {
      let output = "";
      let $this = this;
      this.grades.map((grade) => {
        if ($this.grade === grade.value) {
          output = grade.text;
        }
      });
      return output;
    },
    statementTypes() {
      let $this = this;
      return this.statement_types.filter(function (statement) {
        return $this.selected_statement_type !== statement;
      });
    },
  },
  mounted() {
    console.log("here", this.$auth.can('receipt-cancel'))
    console.log("here", this.$auth.permissions().includes("receipt-cancel"))
    this.getGrades();
    this.getFiscalYear();
  },
  created() {
    if (this.$route.query.frm_date) {
      this.from_date = this.$route.query.frm_date;
    }
    if (this.$route.query.to_date) {
      this.to_date = this.$route.query.to_date;
    }
  },
  watch: {
    enrollCode: function (value) {
      this.items = [];
      this.paginationData = false;
      this.paginationDetailData = false;
    },
    grade: function () {
      this.transactionList = [];
    },
    pagination: function () {
      this.get();
    },
  },

  methods: {
    
    updateTransactionVia(){
      this.$rest.post('/api/update-transaction-via', { transaction_id: this.trasaction_id, transction_via: this.transaction_via})
      .then((res) => {
          this.$events.fire("notification", {
            message: 'Transaction via updated successfully',
            status: "success",
          });
          this.get();
          this.showTransactionViaModal = false;
      })
      .catch((err) => {
        this.$events.fire("notification", {
          message: err.response.data.message,
          status: "error",
        });
      })
    },

    tranactionViaData(id, via) {
      // if(this.$auth.isAdmin()){
      const userRoleSlug = this.$auth.loggedInRole().slug;
      const hasPermission = userRoleSlug === 'administrator' || userRoleSlug === 'accountant';

      if(hasPermission){
        this.showTransactionViaModal = true;
        this.trasaction_id = id;
        this.transaction_via = via
      }
    },
    checkIfMultiple(all, check) {
      const items = [];
      let i = 0;

      for (let index = 0; index < all.length; index++) {
        if (all[index].bill_no === check.bill_no) {
          i++;
        }
      }
      return i;
    },
    receiptCorrectionBackupForceFull(enroll_code) {
      this.$rest
        .post(`/api/invoice-correction-receipt`, {
          correction_enrolls: enroll_code
            ? [enroll_code]
            : this.enroll_code_collection.map((item) => item.enroll_code),
          force_full_correction: true,
        })
        .then(() => {
          this.$events.fire("notification", {
            message: "Force correction complete",
            status: "success",
          });
          this.invoice_correction_modal = false;
          this.get();
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        });
    },
    payOnHead(payNow = false) {
      this.$rest
        .post(`/api/invoice-correction-receipt`, {
          item: this.invoice_correction_details,
          pay_on_head: payNow,
        })
        .then((res) => {
          this.invoice_correction_details_modal = false;
          this.invoice_correction_details = null;
          this.$events.fire("notification", {
            message: "Single invoice correction done",
            status: "success",
          });
          this.receiptCorrectionBackup();
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        });
    },
    receiptCorrectionBackup(
      correctNow = false,
      item = null,
      isAdvanceCorrection = false,
      changeDate = false,
      isCorrectAll = false
    ) {
      const sendAll = [];
      const finalSendAll = [];

      if (isCorrectAll) {
        this.can_be_corrected_all.map((all) => {
          if (!sendAll.includes(all.attached_transaction[0])) {
            sendAll.push(all.attached_transaction[0]);
          }
        });

        sendAll.map((send) => {
          if (this.checkIfMultiple(sendAll, send) === 1) {
            finalSendAll.push(send);
          }
        });
      }

      this.invoice_corrections = [];

      this.$rest
        .post(`/api/invoice-correction-receipt`, {
          correction_transactions: isCorrectAll
            ? finalSendAll
            : item && (isAdvanceCorrection || changeDate)
            ? item
            : item
            ? [item.attached_transaction]
            : this.enroll_code_collection,
          date: this.from_date,
          correctNow,
          isAdvanceCorrection,
          changeDate,
          penalty: {
            invoice: item && item.invoice_no,
            amount: item && item.penalty,
          },
        })
        .then((res) => {
          if (correctNow === true) {
            if (!isAdvanceCorrection) {
              this.receiptCorrectionBackup();
            } else {
              this.get();
            }
            this.$events.fire("notification", {
              message: "Correction was complete",
              status: "success",
            });
          } else {
            this.invoice_corrections = res.data; //.filter(item=>item.attached_transaction.is_correction_done != 1);

            this.can_be_corrected_all = [];
            if (this.invoice_corrections.length > 0) {
              this.invoice_corrections.map((item) => {
                let totalTransactionAmount = 0;
                let totalAdvanceAmount = 0;
                let totalDiscountAmount = 0;
                item.attached_transaction.map((trans) => {
                  totalTransactionAmount += trans.amount;
                  totalAdvanceAmount += trans.advance_amount;
                  totalDiscountAmount += trans.instant_discount;
                });

                if (
                  item.actual_due ===
                    totalTransactionAmount +
                      totalDiscountAmount -
                      totalAdvanceAmount &&
                  item.attached_transaction.length === 1
                ) {
                  this.can_be_corrected_all.push(item);
                } else if (
                  (item.actual_due ===
                    totalTransactionAmount +
                      totalDiscountAmount -
                      totalAdvanceAmount ||
                    item.actual_due > totalTransactionAmount) &&
                  item.attached_transaction.length === 1 &&
                  item.attached_transaction[0].via === "advance"
                ) {
                  this.can_be_corrected_all.push(item);
                }
              });
              this.invoice_correction_modal = true;
            }
          }
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: "Correction Failed",
            status: "error",
          });
        });
    },
    selectStudent(student) {
      if (student) {
        this.enrollCode = student.enroll_code;
      } else {
        this.enrollCode = "";
      }
    },
    selectAccountant(accountant) {
      this.selected_accountant_name = "";
      let accountName = [];
      accountant.map((item) => {
        accountName.push(item.name);
      });

      if (accountName) this.selected_accountant_name = accountName.join(",");
      else this.selected_accountant_name = "";
    },
    transactionCorrection(transaction) {
      this.$rest
        .get("/api/report/transaction/correction/" + transaction.bill_no)
        .then((res) => {
          if (res.data.length === 1) {
            const records = res.data.filter(
              (item) => item.invoice_detail_id === null
            );
            if (records.length) {
              this.correctionTransactionData = records.map((item) => {
                item.transaction_record = transaction;
                return item;
              });
              this.correctionDialog = true;
            }
          }
        });
    },
    correctDetailIdAll(transaction, onlySum = false) {
      if (transaction) {
        let details = transaction.invoice_details_temp;
        if (onlySum) {
          details = [];
          transaction.invoice_details_temp.map((item) => {
            if (
              item.update_stamp === 1 &&
              item.paid_amount &&
              item.updated_at.slice(0, -3) ===
                transaction.transaction_record.created_at.slice(0, -3) &&
              transaction.transaction_record.advance_amount === 0
            ) {
              details.push(item);
            }
          });
        }

        this.$rest
          .post("/api/report/transaction/correction/" + transaction.id, {
            invoice_details_temp: details,
            invoice_no: transaction.invoice.invoice_no,
            bill_no: transaction.bill_no,
          })
          .then((res) => {
            this.$events.fire("notification", {
              message: "Correction was successful",
              status: "success",
            });
            this.correctionDialog = false;
            this.get();
          })
          .catch((err) => {
            this.$events.fire("notification", {
              message: "Correction failed",
              status: "error",
            });
          });
      } else {
        alert("Correction Failed");
      }
    },
    correctDetailId(invoiceDetail, invoiceTransaction, isPreviousDues = false) {
      if (invoiceDetail && invoiceTransaction.transaction_record.amount) {
        let amount = invoiceTransaction.transaction_record.amount;
        if (invoiceTransaction.transaction_record.advance_amount) {
          amount = invoiceDetail.paid_amount;
        }
        this.$rest
          .post("/api/report/transaction/correction/" + invoiceTransaction.id, {
            invoice_detail_id: invoiceDetail.id,
            discount: invoiceDetail.instant_discount,
            paid_amount: amount,
            invoice_no: invoiceDetail.invoice_no,
            bill_no: invoiceTransaction.transaction_record.bill_no,
            detail_ids: [],
          })
          .then((res) => {
            this.$events.fire("notification", {
              message: "Correction was successful",
              status: "success",
            });
            this.correctionDialog = false;
            this.get();
          })
          .catch((err) => {
            this.$events.fire("notification", {
              message: "Correction failed",
              status: "error",
            });
          });
      } else {
        alert("Correction Failed");
      }
    },
    get(params) {
      if (!validateDate(this.to_date) || !validateDate(this.from_date)) return;

      this.noData = true;
      this.moreFilterDialogue = false;
      let via =
        this.transaction_by.length > TOTAL_TRANSACTION_VIA
          ? "all"
          : this.transaction_by.join(",");

      let extraParam =
        "fromDate=" +
        this.from_date.dateForm() +
        "&toDate=" +
        this.to_date.dateForm() +
        "&gradeId=" +
        this.grade.toString() +
        "&via=" +
        via +
        "&enrollCode=" +
        this.enrollCode.toString() +
        "&transactionReport=true" +
        `&transactionListType=${this.selected_transaction_list_type}&accountant=${this.selected_accountant_name}`;
      let query = [null, undefined].includes(params)
        ? this.queryString(extraParam)
        : params;
      this.form.loading = true;
      let endpoint =
        this.selected_statement_type === "Transaction"
          ? "/api/report/transaction"
          : "/api/report/advance";
      endpoint += query;
      this.$rest
        .get(endpoint)
        .then(({ data }) => {
          this.selected_transaction_list_type_after_response =
            this.selected_transaction_list_type;
          this.form.items = data;
          this.enroll_code_collection = [];
          this.enroll_code_collection = data.data.filter(
            (item) => item.is_correction_done === 0
          );
          this.noData = false;
          this.transactionList = [];
          this.totalCollection = 0;
          this.totalInstantDiscount = 0;
          this.totalAdvanceAmount = 0;
          this.transactionList = data.data;
          this.transactionList.map((res) => {
            if (res.is_cancelled !== 1) {
              this.totalCollection += res.amount;
              this.totalAdvanceAmount += res.advance_amount;
              this.totalInstantDiscount += res.instant_discount;
            }
          });
          this.downloadUrl = data.downloadUrl;
          this.printUrl = data.printUrl;
        })
        .catch((e) => {})
        .finally((e) => {
          this.form.loading = false;
        });
    },
    getGrades() {
      this.gradeLoading = true;
      this.grades = [{ value: "all", text: "ALL" }];
      this.$rest
        .get(
          "/api/grades?rowsPerPage=25&sortBy=rank&descending=false&slim=true"
        )
        .then(({ data }) => {
          data.data.map((item) => {
            this.grades.push({ value: item.id, text: item.name });
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },
    paymentRollback(item) {
      this.billNo = item.bill_no;
      this.sodDate = item.transaction_date;
      this.dialog = true;
    },
    transactionRollBack() {
      this.$rest
        .post("/api/fee-payment/rollback-v2", {
          bill_no: this.billNo,
          reason: this.reason,
          sod_date: this.sodDate,
          fiscal_year: this.fiscalYearId,
        })
        .then(({ data }) => {
          this.get();
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });
        });
      this.reasonDialog = false;
      this.reason = "";
    },
    print(item) {
      let url =
        this.printUrl +
        "&receipt=" +
        item.bill_no +
        "&enrollId=" +
        item.enroll_id;
      window.open(url);
    },
    clearFilter() {
      this.from_date = nd.format("YYYY-MM-DD");
      this.to_date = "";
      this.transaction_by = ["Cash", "Cheque", "Khalti", "Esewa"];
      this.grade = "all";
      this.enrollCode = "";
      this.selected_transaction_list_type = "all";
      this.selected_transaction_list_type_after_response = "all";
      this.selected_accountant_name = "";
      this.get();
    },
    download() {
      window.open(this.url);
    },
    getFiscalYear() {
      let years = JSON.parse(window.localStorage.getItem('fiscalyear'));
      this.fiscalYearId = years.is_current ? years.id : '';
    },
  },
};
</script>
<style lang="scss">
table.v-datatable.v-table.theme--light {
  width: 100%;
}

.inner-table {
  border-style: double;
  background: #f1f0f0;
}

.inner-table > tr > td {
  border-style: double;
}

.statement-head {
  .v-menu__activator--active {
    background-color: transparent !important;
  }

  .v-btn {
    padding-left: 0px;
  }

  .v-btn__content {
    color: #666 !important;
    font-size: 22px;
    padding-left: 0 !important;
    text-transform: capitalize;
    font-weight: bold !important;
    border-bottom: 2px solid #666;
  }

  .tableRow {
    border-bottom: 1px solid red !important;
  }
}

.no_correct {
  background: #ccc;
}

.timeDate {
  font-size: 12px;
}
</style>
